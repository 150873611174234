import { Card, CardActionArea, CardContent, CardMedia, Typography, Box, Chip } from '@mui/material';
import {LatestInAndroid} from "../../../models/LatestInAndroid";

interface LatestInAndroidListItemProps {
    latestInAndroid: LatestInAndroid;
}

function LatestInAndroidListItem({ latestInAndroid }: LatestInAndroidListItemProps) {
    return (
        <Card sx={{boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
            <CardActionArea href={latestInAndroid.latestInAndroidLink} target="_blank" sx={{ height: '100%' }}>
                {/* Display source at the top */}
                <Box sx={{ p: 1, bgcolor: 'primary.main', color: 'primary.contrastText', textAlign: 'center' }}>
                    <Typography variant="caption" sx={{ fontWeight: 'bold', letterSpacing: 1.1 }}>
                        {latestInAndroid.latestInAndroidSource.toUpperCase()}
                    </Typography>
                </Box>

                {latestInAndroid.latestInAndroidImage && (
                    <CardMedia
                        component="img"
                        height="180"
                        image={latestInAndroid.latestInAndroidImage}
                        alt={latestInAndroid.latestInAndroidTitle}
                    />
                )}

                <CardContent sx={{ bgcolor: 'background.paper' }}>
                    <Typography gutterBottom variant="h6" component="div" sx={{textAlign:"left"}}>
                        {latestInAndroid.latestInAndroidTitle}
                    </Typography>
                    <Typography variant="body1" component="div" sx={{
                        fontSize: 16,
                        lineHeight: 1.5, // Adjust line height as needed
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3, // Limits the text to 3 lines
                        textAlign:"left"
                    }}>
                        {latestInAndroid.latestInAndroidDesc}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <Typography variant="caption" color="text.secondary">
                            {latestInAndroid.latestInAndroidDate}
                        </Typography>
                        <Chip label={latestInAndroid.latestInAndroidTag} size="small" sx={{ fontSize: '0.75rem' }} />
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default LatestInAndroidListItem;

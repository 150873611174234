import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useFetchApi from "../../hooks/useFetchApi";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MarkdownRenderer from "../../components/common/MarkdownRenderer";
import {PostDetail} from "../../models/PostDetail";
import RelatedPosts from "../../components/RelatedPosts";
import ReactGA from "react-ga4";
import GoogleAd from "../../components/common/GoogleAd";
import adsConfig from "../../adsConfig";

const PostDetails = () => {

    const {postLink} = useParams(); // Retrieve the post link from the URL
    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;


    const {data } = useFetchApi<PostDetail>(`${process.env.REACT_APP_APP_BASE_API_URL}post_detail?url=${postLink}`)
    useEffect(() => {
        // Scroll to top on component load or when postLink changes
        window.scrollTo(0, 0);
    }, [postLink]);
    useEffect(() => {

        if (data) {
            document.title = `${data.postTitle}`
            ReactGA.send({
                hitType: 'pageview',
                page: canonicalUrl,
                title: data.postTitle,
            });
        }
    }, [data, canonicalUrl]);
    return (
        data && (
            <>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        maxWidth: "1100px",
                        margin: "0 auto",
                        textAlign: "start",
                        px: { xs: 2, sm: 3, md: 4 },
                    }}
                >
                    <Typography variant="h1" sx={{ fontWeight: "bold", fontSize: "38px" }}>
                        {data.postTitle}
                    </Typography>
                    <img
                        src={`${process.env.REACT_APP_APP_BASE_IMAGE_URL}` + data.postImage}
                        alt={data.postTitle}
                    />
                    <Box
                        component="section"
                        sx={{ textAlign: "start"}}
                    >
                        <MarkdownRenderer content={data.postDetail} />
                    </Box>

                    <GoogleAd adSlot={adsConfig.slots.multiplexAd} adFormat='autorelaxed'/>

                    <RelatedPosts category={data.postCategory} />
                </Stack>
            </>
        )
    );
};


export default PostDetails;
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import {Avatar, Menu, MenuItem} from "@mui/material";

const drawerWidth = 240;
const navItems = {
    "Home": "/",
    "Latest in Android":"/android",
    "Privacy Policy": "/privacy-policy"
};

function DrawerAppBar(props) {
    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate(); // Correct usage within Router

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleClick = (path) => {
        navigate(path);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick2 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2}}>
                HOWTODOANDROID
            </Typography>
            <Divider/>
            <List>
                {Object.entries(navItems).map(([label, path]) => (
                    <ListItem key={label} disablePadding>
                        <ListItemButton sx={{textAlign: 'center'}} onClick={() => handleClick(path)}>
                            <ListItemText primary={label}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 1, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Avatar
                        alt="Remy Sharp"
                        src="https://www.howtodoandroid.com/images/howtodoandroid_icon.png"
                        sx={{ width: 38, height: 38, mt: 2, mb: 2, mr: 1 }}
                    />

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{
                                display: { xs: 'block', sm: 'block' },
                                fontWeight: 'bold',
                                fontSize: { xs: '16px', sm: '26px' },
                                mr: 1,
                            }}
                            onClick={() => handleClick("/")}
                        >
                            HOWTODOANDROID
                        </Button>

                        {/*<Button
                            variant="contained"
                            color="primary"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '18px',
                                color: 'primary',
                            }}
                            onClick={() => navigate("/android")}
                        >
                            Latest In Android
                        </Button>*/}
                    </Box>

                    <Box sx={{ display: { xs: 'none', sm: 'block' }, ml: 'auto' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ fontWeight: 'bold', fontSize: '18px', m: 1 }}
                            onClick={() => navigate("/privacy-policy")}
                        >
                            Privacy Policy
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>


            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main" sx={{p: 3}}>
                <Toolbar/>
            </Box>
        </Box>
    );
}

DrawerAppBar.propTypes = {
    window: PropTypes.func,
};

export default DrawerAppBar;

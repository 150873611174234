import React from "react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import GoogleAd from "./GoogleAd";
import adsConfig from "../../adsConfig"; // Import your ad component

const buildAbsoluteImageURL = (url: string) => {
    if (!url.startsWith("http")) {
        return `${process.env.REACT_APP_APP_BASE_IMAGE_URL}${url}`;
    }
    return url;
};

const buildAbsoluteURL = (url: string) => {
    if (!url.startsWith("http")) {
        return `${process.env.REACT_APP_APP_BASE_URL}/${url}`;
    }
    return url;
};

const MarkdownRenderer = ({ content }: { content: string }) => {
    return (
        <ReactMarkdown
            className="markdown-content"
            style={{ fontFamily: "Poppins, sans-serif" }}
            components={{
                a: ({ node, ...props }) => (
                    <a
                        {...props}
                        href={buildAbsoluteURL(props.href || "")}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {props.children}
                    </a>
                ),
                img: ({ node, ...props }) => (
                    <img
                        {...props}
                        src={buildAbsoluteImageURL(props.src || "")}
                        alt={props.alt || "Markdown Image"}
                        style={{
                            display: "block",
                            margin: "20px auto",
                            maxWidth: "70%",
                            height: "auto",
                        }}
                    />
                ),
                code: ({ node, inline, className, children, ...props }) => {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                        <SyntaxHighlighter language={match[1]} PreTag="div" {...props}>
                            {String(children).replace(/\n$/, "")}
                        </SyntaxHighlighter>
                    ) : (
                        <code {...props} className={className}>
                            {children}
                        </code>
                    );
                },
                h3: ({ node, ...props }) => {
                    // Dynamically add ads before every <h3>
                    const index = node.position?.start.offset ?? 0; // Use position to create a unique key

                    return (
                        <>
                            <div key={`ad-${index}`} style={{ margin: "20px 0" }}>
                                <GoogleAd
                                    adSlot={adsConfig.slots.inArticleAd}
                                    adStyle={{
                                        display: 'block',
                                        textAlign: 'center',
                                    }}
                                    adFormat="fluid"
                                    layout="in-article"
                                />
                            </div>
                            <h3 {...props}>{props.children}</h3>
                        </>
                    );
                }
            }}
        >
            {content}
        </ReactMarkdown>
    );
};

export default MarkdownRenderer;

const adsConfig = {
    client: process.env.REACT_APP_AD_CLIENT || '',
    slots: {
        displayAdSquare: process.env.REACT_APP_AD_DISPLAY_AD_SQUARE|| '',
        inFeedAd: process.env.REACT_APP_AD_IN_FEED_AD || '',
        inArticleAd: process.env.REACT_APP_AD_IN_ARTICLE_AD || '',
        multiplexAd: process.env.REACT_APP_AD_MULTIPLE_AD || '',
    },
};

export default adsConfig;

import useFetchApi from "../../hooks/useFetchApi";
import {useParams} from 'react-router-dom';

import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect} from "react";
import PostListItem from "../../components/post/PostListItem";
import Divider from "@mui/material/Divider";
import {Post} from "../../models/Post";
import HTDPagination from "../../components/HTDPagination";
import {useStringUtils} from "../../hooks/useStringUtils";
import ReactGA from "react-ga4";
import GoogleAd from "../../components/common/GoogleAd";
import adsConfig from "../../adsConfig";

const CategoryScreen = () => {
    const [page, setPage] = React.useState(0);
    const offset = page > 0 ? (page - 1) * 9 : 0;
    const { replaceHyphenWithSpace } = useStringUtils()
    const categoryNameParam = useParams()["categoryName"];
    const categoryName = categoryNameParam ? replaceHyphenWithSpace(categoryNameParam) : ''

    const {data, totalCount } = useFetchApi<Post[]>(`${process.env.REACT_APP_APP_BASE_API_URL}post/category?category=${categoryName}&limit=9&offset=${offset}`)
    const handleChange = (value: number) => {
        setPage(value);
    };

    useEffect(() => {

        document.title = `Category - ${categoryName}`
        ReactGA.send({
            hitType: 'pageview',
            page: `/category/${categoryName}`,
            title: `Category - ${categoryName} - Page ${page + 1}`,
        });
    }, [categoryName, page]);

    return (
        <Stack direction="column" spacing={2} >
            <Typography variant="h2" sx={{background:"#e3e3e3", p: 2}}>{`Category - ${categoryName}`}</Typography>
            {
                data?.map((post, index) => (
                    <React.Fragment key={post.id}>
                        <PostListItem post={post}/>
                        {index < data.length - 1 && <Divider sx={{my: 2}}/>}
                        {(index + 1) % 3 === 0 && (
                            <React.Fragment>
                                <GoogleAd adSlot={adsConfig.slots.inFeedAd} adFormat='fluid' layoutKey='-ex-7a+dc+4i-oq' />
                                <Divider sx={{ my: 2 }} />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ))
            }

            <HTDPagination  totalPage={totalCount} currentPage={page} onPageChange={handleChange} />
        </Stack>

    );
};

export default CategoryScreen;
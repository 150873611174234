import React, { useEffect } from 'react';
import adsConfig from '../../adsConfig';

interface GoogleAdProps {
    adSlot: string;
    adStyle?: React.CSSProperties;
    adFormat?: string;
    layoutKey?: string;
    layout?: string;
    fullWidthResponsive?: boolean;
}

const GoogleAd: React.FC<GoogleAdProps> = ({
                                               adSlot,
                                               adStyle = { display: 'block' },
                                               adFormat = 'auto',
                                                layoutKey = {},
                                                layout = {},
                                               fullWidthResponsive = true,
                                           }) => {
    useEffect(() => {
        // Ensure adsbygoogle is loaded
        try {
            (window as any).adsbygoogle = (window as any).adsbygoogle || [];
            (window as any).adsbygoogle.push({});
        } catch (error) {
            console.error('AdSense error:', error);
        }
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={adStyle}
            data-ad-client={adsConfig.client}
            data-ad-slot={adSlot}
            data-ad-layout={layout}
            data-ad-layout-key={layoutKey}
            data-ad-format={adFormat}
            data-full-width-responsive={fullWidthResponsive ? 'true' : 'false'}
        ></ins>
    );
};

export default GoogleAd;

import useFetchApi from "../../hooks/useFetchApi";
import {LatestInAndroid} from "../../models/LatestInAndroid";
import {Post} from "../../models/Post";
import React from "react";
import PostListItem from "../../components/post/PostListItem";
import Divider from "@mui/material/Divider";
import LatestInAndroidListItem from "./components/LatestInAndroidListItem";
import Grid from "@mui/material/Grid2";
import PostGridItem from "../../components/post/PostGridItem";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

const LatestInAndroidScreen = () => {

    const {data} = useFetchApi<LatestInAndroid[]>(`${process.env.REACT_APP_APP_BASE_API_URL}latest_in_android?limit=10&offset=0`)

    return (
        <div>
            {
                <Stack direction="column">
                    <Typography variant="h2" sx={{my: 2, fontSize:26}}>Latest In Android</Typography>
                    <Grid container spacing={{xs: 1, md: 2}} columns={{xs: 4, sm: 8, md: 12}}>
                        {data?.map((latestInAndroid, index) => (
                            <Grid key={index} size={{xs: 4, sm: 4, md: 6}}>
                                <LatestInAndroidListItem latestInAndroid={latestInAndroid} key={index}/>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            }
        </div>
    );
}

export default LatestInAndroidScreen;
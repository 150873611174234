import {Stack} from "@mui/material";
import CategoryList from "./CategoryList";
import TopPicks from "./TopPicks";
import React from "react";
import ProfileCard from "../../../components/common/ProfileCard";
import GoogleAd from "../../../components/common/GoogleAd";
import adsConfig from "../../../adsConfig";

const Sidebar = () => {

    return (
        <Stack direction="column" spacing={2} sx={{background: '#dfdfdf', p: 2}}>
            <ProfileCard/>
            <GoogleAd adSlot={adsConfig.slots.displayAdSquare} />
            <GoogleAd adSlot={adsConfig.slots.displayAdSquare} />
            <CategoryList/>
            <TopPicks/>
            <GoogleAd adSlot={adsConfig.slots.displayAdSquare} />
        </Stack>

    );
};

export default Sidebar;